.preloader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.8);
  margin: auto;
  width: 100%;
  height: 100%;
  text-align: center;
}

.preloader img {
  margin-top: 10%;
}
.preloader h2 {
  color: #292664;
}
